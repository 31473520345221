import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'

import { roundToNearest } from '../../helpers/roundToNearest'

const FAQSchema = ({ faqs }) => {
  const questions = []

  faqs.forEach(faq => {
    const { question } = faq
    const answer = faq.answer[0].children[0].text

    questions.push({
      '@type': 'Question',
      name: question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: answer,
      },
    })
  })

  const faqSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: questions,
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(faqSchema) }}
    />
  )
}

export default FAQSchema

FAQSchema.propTypes = {
  faqs: PropTypes.array,
}

FAQSchema.defaultProps = {
  faqs: '',
}
